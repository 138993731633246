<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="blue"
      spinner="mini-spinner"
    />
    <ServerError v-if="ServerError" />

    <v-layout wrap>
      <v-flex xs6>
        <h3 class="bold" style="text-transform: uppercase">
          Training Location
        </h3>
      </v-flex>
      <v-flex xs6 text-right pb-2>
        <v-btn
          v-if="allData.status == 'pending'"
          elevation="10"
          dark
          dense
          small
          color="error"
          ripple="false"
        >
          {{ allData.status }}
        </v-btn>
        <v-btn
          v-else
          elevation="10"
          dark
          dense
          small
          color="green"
          ripple="false"
        >
          {{ allData.status }}
        </v-btn>
      </v-flex>
      <v-flex xs12>
        <TrainingMap :lat="allData.location[1]" :lon="allData.location[0]" />
      </v-flex>
    </v-layout>

    <v-layout wrap>
      <v-flex xs12>
        <v-card>
          <v-layout wrap pa-5>
            <v-flex xs12 md6>
              <v-layout wrap>
                <v-flex xs12 md4>
                  <span class="sbold">Division:</span> &nbsp;
                </v-flex>
                <v-flex xs12 md8>
                  <span class="regular">{{
                    allData.divisionId.divisionName
                  }}</span>
                </v-flex>

                <v-flex xs12 md4>
                  <span class="sbold">Range:</span> &nbsp;
                </v-flex>

                <v-flex xs12 md8>
                  <span class="regular">{{ allData.rangeId.rangeName }}</span>
                </v-flex>

                <v-flex xs12 md4 pt-1>
                  <span class="sbold">Date:</span> &nbsp;
                </v-flex>
                <v-flex xs12 md8>
                  <span class="regular"
                    >{{ new Date(allData.create_date).toLocaleString() }}
                  </span>
                </v-flex>

                <!-- <v-flex xs12 >
                  <span class="sbold">Distance Covered  </span> &nbsp;
                  <span class="regular">{{allData. distanceWalked   }}  (m)       </span>
                </v-flex> -->
              </v-layout>
            </v-flex>
            <v-flex xs12 md6 align-self-center>
              <v-card class="" elevation="4" style="background-color: grey">
                <v-layout wrap style="color: white">
                  <v-flex xs12 px-2 pt-1>
                    <span class="sbold">Name:</span> &nbsp;
                    <span class="regular"
                      >{{ allData.userid.firstName }} &nbsp;{{
                        allData.userid.lastName
                      }}</span
                    >
                  </v-flex>
                  <v-flex xs12 px-2>
                    <span class="sbold">Phone:</span> &nbsp;
                    <span class="regular">{{ allData.userid.phone }}</span>
                  </v-flex>
                  <v-flex xs12 px-2 pb-1>
                    <span class="sbold">Email:</span> &nbsp;
                    <span class="regular">{{ allData.userid.email }} </span>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs12 pt-3>
        <span class="bold" style="text-transform: uppercase">
          <h3>Participents</h3></span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex xs12>
        <v-card>
        <!-- <v-data-table
          style="cursor: pointer"
          :headers="headers"
          :items="participents"
          class="elevation-1"
          hide-default-footer
        >
          <template v-slot:item.create_date="{ item }">
            <span>{{ new Date(item.create_date).toLocaleString() }}</span>
          </template>
        </v-data-table> -->
        <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Name
          </th>
          <th class="text-left">
            Designation
          </th>
          <th class="text-left">
            Contact Number
          </th>
          <th class="text-left">
            Area in Charge
          </th>
        </tr>
      </thead>
      <tbody>
        <tr  v-for="item in participents"
        :key="item.title">
        <td>{{item.name}}</td>
        <td>{{item.designation}}</td>
        <td>{{item.contactNumber}}</td>
        <td>{{item.areaInCharge}}</td>


        </tr>
      </tbody>
     </template>
</v-simple-table>
</v-card>
      </v-flex>
    </v-layout>
    <v-layout wrap pt-3>
      <v-flex xs6>
        <h3 class="bold" style="text-transform: uppercase">Training photos</h3>
      </v-flex>

      <template>
        <v-flex xs12 pt-1>
          <v-card>
            <v-layout wrap pa-5>
              <v-flex xs12>
                <h4 style="border-bottom: 3px solid black"></h4>
              </v-flex>

              <v-flex xs12 pt-3 pr-1 align-self-center text-center>
                <v-card outlined>
                  <!-- <v-card-title>Training photos</v-card-title> -->
                  <v-card-text>
                    <v-layout wrap>
                      <v-flex
                        pa-1
                        xs12
                        md4
                        lg4
                        v-for="(item2, k) in allData.photo"
                        :key="k"
                      >
                        <v-img
                          :src="mediaURL + item2"
                          @click="(imageDialog = true), (dialogImage = item2)"
                          style="cursor: pointer"
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color=" #FDAC53"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <!-- <v-flex xs12 sm6 pt-3 pl-1>
                 <v-card outlined v-if="item.photo.length > 0">
                   <v-card-title>Snare Photos</v-card-title>
                   <v-card-text>
                  <v-layout wrap>
                    <v-flex
                      xs12
                      md4
                      lg4
                      pa-1
                      v-for="(item3, j) in item.photo"
                      :key="j"
                    >
                      <v-img :src="baseURL + '/file/get/' + item3" @click="imageDialog =true,dialogImage = item3" style="cursor:pointer">
                       <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color=" #FDAC53"
                      ></v-progress-circular>
                    </v-row>
                  </template> </v-img>
                    </v-flex>
                  </v-layout>
                  </v-card-text>
                </v-card>
               <span v-else class=" py-2" style=" font-family:pregular" > No snare photo uploads.... </span>

              </v-flex> -->
            </v-layout>
          </v-card>
        </v-flex>
      </template>
    </v-layout>
    <v-layout wrap  v-if="allData.status == 'pending'">
      <v-flex xs4 pt-5>
        <v-btn
          block
          dark
          dense
          small
          color="green"
          @click="approveDialog = true"
        >
          Approve ASW Training
        </v-btn>
      </v-flex>

      <v-flex xs4 pt-5 py-3>
      <v-flex  px-6>
        <v-btn
        
          block
          dark
          dense
          small
          color="orange"
          @click="approveDialog1 = true"
        >
          Reject ASW Training
        </v-btn>
</v-flex>
      </v-flex>


      <v-flex xs4 pt-5>
        <v-flex px-4>
        <v-btn
          block
          dark
          dense
          small
          color="red"
          @click="approveDialog2 = true"
        >
          Delete ASW Training
        </v-btn>
      </v-flex>
      </v-flex>


    </v-layout>













    <v-dialog width="600px" v-model="approveDialog">
      <v-card width="600px">
        <v-layout wrap pa-3>
          <v-flex xs12 class="sbold" text-center>
            Are you sure you want to Approve ASW Training.....?
          </v-flex>
          <v-flex xs12 text-right pt-2>
            <v-btn color="primary" small text @click="approveTraining()">
              ok</v-btn
            >
            <v-btn color="error" small text @click="approveDialog = false">
              cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>




    <v-dialog width="600px" v-model="approveDialog1">
      <v-card width="600px">
        <v-layout wrap pa-3>
          <v-flex xs12 class="sbold" text-center>
            Are you sure you want to Reject ASW Training.....?
          </v-flex>
          <v-flex xs12 text-right pt-2>
            <v-btn color="primary" small text @click="rejectTraining()">
              ok</v-btn
            >
            <v-btn color="error" small text @click="approveDialog1 = false">
              cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>




    <v-dialog width="600px" v-model="approveDialog2">
      <v-card width="600px">
        <v-layout wrap pa-3>
          <v-flex xs12 class="sbold" text-center>
            Are you sure you want to Delete ASW Training.....?
          </v-flex>
          <v-flex xs12 text-right pt-2>
            <v-btn color="primary" small text @click="deleteTraining()">
              ok</v-btn
            >
            <v-btn color="error" small text @click="approveDialog2 = false">
              cancel</v-btn
            >
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>





    <v-dialog v-model="imageDialog" width="600px">
      <v-card>
        <v-img :src="mediaURL + dialogImage">
          <template v-slot:placeholder>
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-progress-circular
                indeterminate
                color=" #FDAC53"
              ></v-progress-circular>
            </v-row>
          </template>
        </v-img>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import TrainingMap from "./trainingmap";
export default {
  components: {
    TrainingMap,
  },

  data() {
    return {
      approveDialog: false,
      approveDialog1: false,
      approveDialog2: false,
      imageDialog: false,
      dialogImage: null,
      appLoading: false,
      ServerError: false,
     
      records: [],
      gmap2: null,
      latt: null,
      lonn: null,
      icons: "",
      photos: [],
      user: [],
      video: null,
      boatDocument: null,
      GroupData: [],
      allData: [],
      participents: [],
      snareReports: [],
      gmap: null,
      headers: [
        { text: "Name", value: "name" },
        {
          text: "Designation ",
          align: "start",
          sortable: false,
          value: "designation",
        },
        {
          text: "ContactNumber ",
          align: "start",
          sortable: false,
          value: "contactNumber",
        },
        {
          text: "Area InCharge:",
          align: "start",
          sortable: false,
          value: "areaInCharge",
        },

        // { text: " Not Removed Traps", value: "noOfTrapsNotRemoved" },
      ],
      documentFile1: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        url: "/training/detail/admin",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
          page: this.page,
          limit: 25,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.allData = response.data.data;
            this.participents = response.data.data.particepents;
            // this.snareReports = response.data.reports;
            console.log("allData", this.allData);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    approveTraining() {
      this.appLoading = true;
      axios({
        url: "/admin/approve/training",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.approveDialog = false;
            this.getData();
            // this.snareReports = response.data.reports;
            console.log("allData", this.allData);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },


    deleteTraining() {
      this.appLoading = true;
      axios({
        url: "/admin/delete/training",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.approveDialog2 = false;
            this.getData();
            // this.snareReports = response.data.reports;
            console.log("allData", this.allData);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },









    rejectTraining() {
      this.appLoading = true;
      axios({
        url: "/admin/reject/training",
        method: "post",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.$route.query.id,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.showsnackbar = true;
            this.msg = response.data.msg;
            this.approveDialog1 = false;
            this.getData();
            // this.snareReports = response.data.reports;
            console.log("allData", this.allData);
          } else {
            this.showsnackbar = true;
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },












  },
};
</script>

<style scoped>
.v-data-table,
.v-text-field {
  font-family: pregular;
}
</style>